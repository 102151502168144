import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from 'src/app/pages/_services/data.service';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-ventures',
  templateUrl: './ventures.component.html',
  styleUrls: ['./ventures.component.scss']
})
export class VenturesComponent implements OnInit, AfterViewInit {

  list; listall;
  term;catcurrent='All';

  constructor(
    private dataService: DataService) {
  }

  ngOnInit() {
    let tempSubscription = this.dataService.getList("ventures").subscribe((data) => {
      tempSubscription.unsubscribe();

      data.forEach(element => {
        // if (element['description'])
        // element['description'] = element['description'].substr(0, 150);
      });
      
      this.list = this.listall = data.filter(function (entry) {
        return entry['description'];
      });
    })

    // this.list = [
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    //   {
    //     id: UUID.UUID(),
    //     title:"Ventures1",
    //     description: "rgdfg dfgdfgdf gdfg dfgfdg gdfg gd gdfgdf"
    //   },
    // ]
  }
  ngAfterViewInit() {

  }

  filtercat(cat) {
    this.catcurrent = cat;

    if (cat == 'All')
      this.list = this.listall
    else
      this.list = this.listall.filter(function (entry) {
        return entry['category'] && entry['category'] === cat;
      });
  }

}
