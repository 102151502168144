

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Base } from './base';

@NgModule({
  declarations: [
    Base
  ],
  imports: [
    CommonModule,
  ],
  providers: []

})
export class BaseModule { }
