import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit, AfterViewInit{

  list;
  term;
  selected=1;;
  
  constructor(
    private dataService: DataService) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  setarticles(){
    //delete this.selected;
  }

}
