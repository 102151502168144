import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { RouterService } from "./core/services/router.service";
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
//import { CookieService } from 'ngx-cookie-service';
import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-ubold',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  url; isHome;
  menumobile = false;
  loaded; 

  constructor(private routerService: RouterService,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    //private cookieService: CookieService,
    public dataService: DataService) {
    router.events.subscribe((val) => {

      // if (this.url && this.url != this.router.url){
      //   this.addscript(this.elementRef.nativeElement, "assets/site/js/owl.carousel.min.js", "owlcarouselminjs");
      //   // this.addscript(this.elementRef.nativeElement, "assets/site/js/snap.svg-min.js", "snapsvgminjs");
      //   // this.addscript(this.elementRef.nativeElement, "assets/site/js/jquery.listtopie.min.js", "jquerylisttopieminjs");
      //   // this.addscript(this.elementRef.nativeElement, "assets/site/js/animation.js", "jsanimationjs");
        this.addscript(this.elementRef.nativeElement, "assets/site/js/custom.js", "jscustomjs");
      // }

      if (this.ismobile() && this.url != "/" && this.url != this.router.url)
      this.menumobile = false;

      this.url = this.router.url;
      this.isHome = this.url == "/" || this.url.indexOf("home") >= 0;
      

    });

    routerService.loadRouting();
  }

  ngOnInit() {
    this.url = this.router.url;
    this.isHome = this.url == "/" || this.url.indexOf("home") >= 0;

    this.route.params.subscribe(async params => {
      this.url = this.router.url;
      this.isHome = this.url == "/" || this.url.indexOf("home") >= 0;
    })
  }

  ngAfterViewInit() {
    if (this.ismobile())
      this.menumobile = false;
    else
      this.menumobile = true;   

      this.loaded = true;
  }

  clickmenu(){
    if (this.ismobile())
    this.menumobile = false;
  }

  approve() {
    if ((<HTMLInputElement>document.getElementById("emailinput")).value) {
      let item = {
        date: new Date(),
        email: (<HTMLInputElement>document.getElementById("emailinput")).value
      }
      this.dataService.saveItem("tt_emails", item)
    }
  }

  showAddress() {
    return this.dataService.coinbase.substr(0, 7) + "..." + this.dataService.coinbase.substr(this.dataService.coinbase.length - 5, this.dataService.coinbase.length)
  }

  async login() {

    this.router.navigate(['/login']);
    return;

    try {
      await this.dataService.initweb3();

      if (!this.dataService.coinbase || !this.dataService.userRegistered) {
        return;
      }

      this.dataService.getByID("tt_users", this.dataService.coinbase).subscribe((data) => {
        if (!data || !data['email']) {
          this.router.navigate(['/login']);
        }
        else {
          this.dataService.userEmail = data['email'];
        }
      })

      if (this.dataService.networkid != environment.chain) {
        await this.dataService.setnetworkinit();
      }

      this.dataService.afterinit();
    }
    finally {

    }
  }

  signout() {
    this.dataService.deleteCookieItem();
    this.dataService.struser = "";

    delete this.dataService.coinbase;
    delete this.dataService.userRegistered;
    delete this.dataService.userEmail;

    this.router.navigate(['/']);
    //window.location.href = window.location.origin + '/';
    // this.auth.SignOut();
    // this.router.navigate(['/auth/login']);
  }

  setmenu() {
    console.log('a');
    this.menumobile = !this.menumobile;
  }

  addscript(_elem, _file, _class, _class_general = '') {

    let content = document.getElementsByTagName('body')[0];
    let arr = document.getElementsByClassName(_class);
    for (let i = 0; i < arr.length; i++) {
      arr[i].remove();
      i = i - 1;
    }

    var s = document.createElement("script");
    s.type = "text/javascript"; //"text/javascript";

    s.classList.add(_class);
    if (_class_general != '')
      s.classList.add(_class_general);

    s.src = _file;
    _elem.appendChild(s);

    return s;
  }

  ismobile() {
    return window.innerWidth < 1024;
  }

}
