

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'

import { HomeComponent } from './pages/home/home.component';
import { Home2Component } from './pages/home2/home2.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { TokenizationComponent } from './pages/tokenization/tokenization.component';
import { BuilderComponent } from './pages/builder/builder.component';
import { VenturesComponent } from './pages/ventures/ventures.component';
import { BlogComponent } from './pages/blog/blog.component';
import { DetailsComponent } from './pages/details/details.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { VCComponent } from './pages/vc/vc.component';
import { ProfitShareComponent } from './pages/profile/profitshare.component';
import { ProfitShareComponent as profit2 } from './pages/profitshare/profitshare.component';
import { ChatBotComponent } from './pages/chatbot/chatbot.component';
import { LoginComponent as login2 } from './pages/login/login.component';
import { LoginComponent } from './pages/login2/login.component';

import { HashLocationStrategy, PathLocationStrategy, LocationStrategy, Location } from '@angular/common';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';

// import {environment} from 'src/environments/environment';
// import { AngularFirestoreModule } from 'angularfire2/firestore';
// import { AngularFireModule } from 'angularfire2';
// import { AngularFireDatabaseModule } from 'angularfire2/database';
// import { AngularFireAuthModule } from 'angularfire2/auth';

//import {CalendarModule} from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
//import {DataTableModule} from 'primeng/datatable';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { TabViewModule } from 'primeng/tabview';
/*import {TabPanel} from 'primeng/tabpanel';*/
/*import {Column} from 'primeng/column';*/
import { ContextMenuModule } from 'primeng/contextmenu'
/*import {MenuItem, Header, Footer} from 'primeng/components/common/api';*/
import {EditorModule} from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseModule } from 'src/app/pages/_ewo/base.module';

import { BaseService as EwoService } from 'src/app/pages/_ewo/service/service';
import { BaseService } from 'src/app/pages/_services/base.service'

import { PipesModule } from "./pipes/pipes.module";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Home2Component,
    ProjectsComponent,
    TokenizationComponent,
    BuilderComponent,
    VenturesComponent,
    BlogComponent,
    DetailsComponent,
    GroupsComponent,
    VCComponent,
    ProfitShareComponent,
    profit2,
    ChatBotComponent,
    LoginComponent,
    login2
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    BaseModule,

    FormsModule,
    ReactiveFormsModule,
    
    AngularFireModule.initializeApp(environment.firebase),
    NgxSmartModalModule.forRoot(),
    // InMemoryWebApiModule.forRoot(FakeDbService),

    PipesModule,

    // AngularFireModule.initializeApp(environment.firebase, 'angular-auth-firebase'),
    // AngularFirestoreModule,
    // AngularFireDatabaseModule,   
    // AngularFireAuthModule,  

    //CalendarModule, DataTableModule, EditorModule
    DropdownModule, TableModule, TreeTableModule, TreeModule, TabViewModule, CodeHighlighterModule, DialogModule, ButtonModule, InputTextModule, CheckboxModule,
    ContextMenuModule, EditorModule, FileUploadModule,
    ModalModule.forRoot(),
  ],
  providers: [
    EwoService, BaseService, AngularFireAuth, AngularFirestore, BsModalService,
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
     Location, {provide: LocationStrategy, useClass: PathLocationStrategy}
    //  {provide: LocationStrategy, useClass: HashLocationStrategy}


    // provider used to create fake backend
    // FakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
