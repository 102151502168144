import { Component, OnInit, AfterViewInit, ElementRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/pages/_services/data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss']
})
export class Home2Component implements OnInit, AfterViewInit{

  list; listall;
  term;catcurrent='All';

  userExist;userInfo;currentUser;

  tableusers = "cryptoaz_users"
  tableregister = "cryptoaz_registration"
  tableinvitestemp = "cryptoaz_invites_temp"
  tableinvites = "cryptoaz_invites"
  tablebuy = "cryptoaz_buy"
  tablemsg = "cryptoaz_messages"

  loaded;

  modalRef: BsModalRef;
  submit;email;message;type;
  
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    public router: Router,
    private elementRef: ElementRef,
    private modalService: BsModalService) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
			let self = this;

			if (params['code']){
				this.dataService.invitecode = params['code'];
        localStorage.setItem("tt_invitecode", params['code']);
			}
    });

    let tempSubscriptionx = this.dataService.getList("projects").subscribe((data) => {
      tempSubscriptionx.unsubscribe();

      data = data.filter(function (entry) {
        return entry['cryptoaz'];
      });

      data.forEach(element => {
        element['category'] = 'Investment';
        if (element['description'])
        element['description'] = element['description'].substr(0, 100);
      });

      let tempSubscription = this.dataService.getList("projects_community").subscribe((datax) => {
        tempSubscription.unsubscribe();
  
        datax.forEach(elementx => {
          elementx['community'] = true;
          if (!elementx['category'])
          elementx['category'] = 'Community';
        });
  
        this.list = this.dataService.sortField(data, 'winx', 'desc');

        this.list = this.listall = [...this.list, ...datax]
        // this.list = this.list.slice(0, 5);
      })

    });

    this.loaduser();
  }

  ngAfterViewInit() {

  }

  filtercat(cat) {
    this.catcurrent = cat;

    if (cat == 'All')
      this.list = this.listall
    else
      this.list = this.listall.filter(function (entry) {
        return entry['category'] && cat.indexOf(entry['category']) >= 0;
      });
  }

  async loaduser(){
    if (this.dataService.coinbase){

      //this.dataService.initweb3();

      this.dataService.getByID(this.tableusers, this.dataService.coinbase).subscribe((data) => {
        if (data)
          this.currentUser = data;

          if (data && data['email']){
            this.dataService.userEmail = data['email'];
          }

        this.loaded = true;
      });

      this.userExist = await this.dataService.userExist();
      if (this.userExist)
      this.userInfo = await this.dataService.tokenContract.methods.users(this.dataService.coinbase).call();

    }
  }

  async login(){
    await this.dataService.initweb3();

    this.userExist = await this.dataService.userExist();

    if (this.userExist){
      this.loaduser();
    }
    else
    this.router.navigate(['/login']);

  }

  openModal(template: TemplateRef<any>, type) {
    delete this.type;
    delete this.message;
    this.type = type;

    this.submit = false;

    this.modalRef = this.modalService.show(template,{
      backdrop: 'static',
    });
 }

  sendmsg(){
    this.submit = true;

    if (!this.email || !this.message)
    return;

    this.dataService.saveSubItem(this.tablemsg, this.currentUser.uid, "messages", {id: UUID.UUID(), email: this.email, content: this.message, type: this.type} ).then((data) => {
      delete this.email;
      delete this.message;
      
      this.modalRef.hide()
    })
  }

}
