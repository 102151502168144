import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, AfterViewInit{

  item;
  
  constructor(
    private route: ActivatedRoute,
    public dataService: DataService) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
			let self = this;

			if (params['id']){
				this.dataService.getItemByID("projects", params['id']).subscribe((data) => {

          if (data){
            this.item = data;
            if (this.item['website'] && this.item['website'].toString().indexOf('https://') < 0)
            this.item['website'] = 'https://'+this.item['website'];
          }
          else {
            this.dataService.getItemByID("projects_community", params['id']).subscribe((data) => {

              if (data){
                data['community'] = true;
                this.item = data;
  
                if (this.item['website'] && this.item['website'].toString().indexOf('https://') < 0)
                this.item['website'] = 'https://'+this.item['website'];
              }
              else{
                let list = [
                  {
                    title: "TokenList",
                    category: "SmartContract",
                    description: "TokenList allows users to manage an entire cryptocurrency portfolio through their app."
                  },
                  {
                    title: "StakeLand",
                    category: "SmartContract",
                    description: "StakeLand is a platform where users can stake a custom token and receive rewards."
                  },
                  {
                    title: "Marketplace",
                    category: "Marketplace",
                    description: "The NFT Marketplace provides you with the opportunity to create (or as we call it, mint), sell, purchase, bid on, collect, trade NFTs."
                  },
                  {
                    title: "DeFi",
                    category: "DeFi",
                    description: "An easy-to-use DeFi interface with lots of features available. Users of the platform will experience zero stress when it comes to automated DeFi trading and farming."
                  },
                  {
                    title: "BEP20 Token Generator", 
                    category: "Token",
                    description: "BEP20 Generator is the easiest and fastest way to create your own BEP20 token on the Binance Smart Chain network."
                  },
                  {
                    title: "Token",
                    category: "Token",
                    description: "A crypto token is a representation of an asset or interest that has been tokenized on an existing cryptocurrency's blockchain."
                  },
                  {
                    title: "Invoicer",
                    category: "Token",
                    description: "Invoicer is an easy invoice management suite for Web3 teams."
                  },
                  {
                    title: "ChatToken",
                    category: "Token",
                    description: "ChatToken dapp enables ICP token transfers via instant message."
                  },
                  {
                    title: "AiBlog.agency",
                    category: "Token",
                    description: "AiBlog is an artificial intelligence assistant that helps you create high-quality blog content, in just a few minutes, at a fraction of the cost."
                  }
                ]

                list.forEach(element => {
                  if (element['title'] == params['id']){
                    //element['community'] = true;
                    this.item = element;
                  }
                });
              }
            })
          }
				})
			}
    });

  }
  ngAfterViewInit() {

  }

  send(){
    document.getElementById('lblsent').style.display = 'block';
    setTimeout(() => {
      document.getElementById('lblsent').style.display = 'none';
    }, 3000);
  }

}
