import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UUID } from 'angular2-uuid';

import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-profitshare',
  templateUrl: './profitshare.component.html',
  styleUrls: ['./profitshare.component.scss']
})
export class ProfitShareComponent implements OnInit {

  loaded;
  currentUser;
  invitesall=[];invitesvalid=[];


  tableusers = "cryptoaz_users"
  tableregister = "cryptoaz_registration"
  tableinvitestemp = "cryptoaz_invites_temp"
  tableinvites = "cryptoaz_invites"
  tablebuy = "cryptoaz_buy"

  bPay;userInfo;
  userExist;activated1;activated2;activated3;
  levelCost:any[]=[];

  modalReference;

  constructor(
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private modalService: NgbModal,
    public router: Router,
    public dataService: DataService) {
      this.levelCost[1] = 18;
      this.levelCost[2] = 30;
      this.levelCost[3] = 45;
     }

  async ngOnInit() {

    let self = this;

    // if (!this.dataService.coinbase){
    //   this.router.navigateByUrl('/login');
    // }

    // this.route.params.subscribe(async params => {
    //   let self = this;

    //   if (params['code']) {
    //     this.dataService.invitecode = params['code'];
    //     this.dataService.inviteaddress = await this.dataService.tokenContract.methods.idToAddress(this.dataService.invitecode).call();
    //   }
    // });

    this.loaduser();

  }

  async loaduser(){
    if (this.dataService.coinbase){

      //this.dataService.initweb3();

      this.dataService.getByID(this.tableusers, this.dataService.coinbase).subscribe((data) => {
        if (data)
          this.currentUser = data;

          if (data && data['email']){
            this.dataService.userEmail = data['email'];
          }

        if (!this.currentUser['coderef']){

          this.currentUser['coderef'] = this.currentUser.id.substring(3,7)+UUID.UUID().substring(1,7);
          this.dataService.saveItem(this.tableusers, {id: this.dataService.coinbase, coderef: this.currentUser['coderef']} ).then((data) => {

          })
        }

        this.loaded = true;
      })

      this.userExist = await this.dataService.userExist();
      if (this.userExist)
      this.userInfo = await this.dataService.tokenContract.methods.users(this.dataService.coinbase).call();

    }
  }

  async login(){
    await this.dataService.initweb3();

    this.userExist = await this.dataService.userExist();

    if (this.userExist){
      this.loaduser();
    }
    else
    this.router.navigate(['/login']);

  }

  logout() {
    this.afAuth.signOut();
    localStorage.removeItem('crypto_currentUser');
    this.router.navigate(['/auth/login']);
  }

  copyCode() {

    var tempInput = <HTMLInputElement>document.createElement("input");
    tempInput.setAttribute('style', "position: absolute; left: -1000px; top: -1000px");
    tempInput.value = window.location.origin + '/#/home/'+this.currentUser['coderef'];

    document.body.appendChild(tempInput);
    tempInput.select();
    const copyStatus = document.execCommand("copy");
    document.body.removeChild(tempInput);

    if (copyStatus) {
      document.getElementsByClassName('copy-bedge')[0]
        .classList.add('copy-bedge-show')

      setTimeout(() => {
        document.getElementsByClassName('copy-bedge')[0].classList.remove('copy-bedge-show')
      }, 2000)
    }

  }

  activate(level){
    this.buynewlevelbusd(level)
  }

  async buynewlevelbusd(level) {
    await this.buynewlevelbase(environment.contractBusd.address, level, 0);
  }

  async buynewlevelusdt(level) {
    await this.buynewlevelbase(environment.contractUsdt.address, level, 0);
  }

  async buynewlevelbase(tokenaddress, level, amount) {
    let self = this;
    if (!this.dataService.networkok())
      return;

    //this.closemodal();

    this.bPay = true;

    //BNB pay to contract
    await this.dataService.tokenContract.methods
      .buyLevel(1, level, tokenaddress, (this.levelCost[level]/3)*1e12)
      .send({ from: this.dataService.coinbase, value: amount,  gasLimit: 300000 })
      .on("receipt", async (data) => {
        this.bPay = false;
        document.getElementById('closebuymodal').click();
        this.afterBuy(data);
      })
      .on("error", (error) => {
        this.bPay = false;
        document.getElementById('closebuymodal').click();
      })
  }

    async afterBuy(data){
    data['date'] = new Date();
    data['userAddress'] = this.dataService.coinbase;
    //data['sponsorAddress'] = this.dataService.tokenAddress.addressbsc;
    data['contractAddress'] = this.dataService.tokenAddress;

    let temp_ = JSON.stringify(data);
    temp_ = JSON.parse(temp_.replace(/null/g, '"null"'));
    this.dataService.saveItem(this.tablebuy, temp_);

    await this.dataService.initweb3();
    this.router.navigate(['/projects']);
    //document.getElementsByTagName('body')[0].click();
    //window.location.href = window.location.origin + '/pages/b3b4';
  }

  async withdraw(){

    if(!this.dataService.coinbase || !this.dataService.tokenContract){
      alert('Login!');
      return;
      
    }
    await this.dataService.tokenContract.methods
    .withdraw(environment.contractBusd.address, 60000000000000)
    .send({ from: this.dataService.coinbase, value: 0,  gasLimit: 300000 })
    .on("receipt", async (data) => {
    })
    .on("error", (error) => {
    })
  }

}
