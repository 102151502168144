import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-vc',
  templateUrl: './vc.component.html',
  styleUrls: ['./vc.component.scss']
})
export class VCComponent implements OnInit, AfterViewInit{

  buytoken = "busd";
  
  constructor(
    private dataService: DataService) {
  }

  ngOnInit() {

  }
  ngAfterViewInit() {

  }

}
