import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, AfterViewInit {

  list; listall;
  term;catcurrent='All';

  constructor(
    private dataService: DataService) {
  }

  ngOnInit() {
    let tempSubscription = this.dataService.getList("projects").subscribe((data) => {
      tempSubscription.unsubscribe();

      this.list = this.listall = this.dataService.sortDate(data, 'desc');
    })
  }
  ngAfterViewInit() {

  }

  filtercat(cat) {
    this.catcurrent = cat;

    if (cat == 'All')
      this.list = this.listall
    else
      this.list = this.listall.filter(function (entry) {
        return entry['category'] && entry['category'] === cat;
      });
  }

}
