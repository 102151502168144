import { Component, OnInit, AfterViewInit, ElementRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/pages/_services/data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, AfterViewInit{

  list; listall;
  term;catcurrent='All';

  userExist;userInfo;currentUser;

  tableusers = "cryptoaz_users"
  tableregister = "cryptoaz_registration"
  tableinvitestemp = "cryptoaz_invites_temp"
  tableinvites = "cryptoaz_invites"
  tablebuy = "cryptoaz_buy"
  tablemsg = "cryptoaz_messages"

  loaded;

  modalRef: BsModalRef;
  submit;email;message;type;
  
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    public router: Router,
    private elementRef: ElementRef,
    private modalService: BsModalService) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
			let self = this;

			if (params['code']){
				this.dataService.invitecode = params['code'];
        localStorage.setItem("tt_invitecode", params['code']);
			}
    });

    // let tempSubscriptionx = this.dataService.getList("projects").subscribe((data) => {
    //   tempSubscriptionx.unsubscribe();

    //   data = data.filter(function (entry) {
    //     return entry['cryptoaz'];
    //   });

    //   data.forEach(element => {
    //     element['category'] = 'Investment';
    //     if (element['description'])
    //     element['description'] = element['description'].substr(0, 100);
    //   });

    //   let tempSubscription = this.dataService.getList("projects_community").subscribe((datax) => {
    //     tempSubscription.unsubscribe();
  
    //     datax.forEach(elementx => {
    //       elementx['community'] = true;
    //       if (!elementx['category'])
    //       elementx['category'] = 'Community';
    //     });
  
    //     //this.list = this.dataService.sortField(data, 'winx', 'desc');

    //     //this.list = this.listall = [...this.list, ...datax]
    //     // this.list = this.list.slice(0, 5);
    //   })

    // });

    this.list = [
      {
        id: UUID.UUID(),
        title: "TokenList",
        category: "SmartContract",
        description: "TokenList allows users to manage an entire cryptocurrency portfolio through their app."
      },
      {
        id: UUID.UUID(),
        title: "StakeLand",
        category: "SmartContract",
        description: "StakeLand is a platform where users can stake a custom token and receive rewards."
      },
      {
        id: UUID.UUID(),
        title: "Marketplace",
        category: "Marketplace",
        description: "The NFT Marketplace provides you with the opportunity to create (or as we call it, mint), sell, purchase, bid on, collect, trade NFTs."
      },
      {
        id: UUID.UUID(),
        title: "DeFi",
        category: "DeFi",
        description: "An easy-to-use DeFi interface with lots of features available. Users of the platform will experience zero stress when it comes to automated DeFi trading and farming."
      },
      {
        id: UUID.UUID(),
        title: "BEP20 Token Generator", 
        category: "Token",
        description: "BEP20 Generator is the easiest and fastest way to create your own BEP20 token on the Binance Smart Chain network."
      },
      {
        id: UUID.UUID(),
        title: "Token",
        category: "Token",
        description: "A crypto token is a representation of an asset or interest that has been tokenized on an existing cryptocurrency's blockchain."
      },
      {
        id: UUID.UUID(),
        title: "Invoicer",
        category: "Token",
        description: "Invoicer is an easy invoice management suite for Web3 teams."
      },
      {
        id: UUID.UUID(),
        title: "ChatToken",
        category: "Token",
        description: "ChatToken dapp enables ICP token transfers via instant message."
      },
      {
        id: UUID.UUID(),
        title: "AiBlog.agency",
        category: "Token",
        description: "AiBlog is an artificial intelligence assistant that helps you create high-quality blog content, in just a few minutes, at a fraction of the cost."
      }
    ]

    this.loaduser();
  }

  ngAfterViewInit() {

  }

  filtercat(cat) {
    this.catcurrent = cat;

    if (cat == 'All')
      this.list = this.listall
    else
      this.list = this.listall.filter(function (entry) {
        return entry['category'] && cat.indexOf(entry['category']) >= 0;
      });
  }

  async loaduser(){
    if (this.dataService.coinbase){

      //this.dataService.initweb3();

      this.dataService.getByID(this.tableusers, this.dataService.coinbase).subscribe((data) => {
        if (data)
          this.currentUser = data;

          if (data && data['email']){
            this.dataService.userEmail = data['email'];
          }

        this.loaded = true;
      });

      this.userExist = await this.dataService.userExist();
      if (this.userExist)
      this.userInfo = await this.dataService.tokenContract.methods.users(this.dataService.coinbase).call();

    }
  }

  async login(){
    await this.dataService.initweb3();

    this.userExist = await this.dataService.userExist();

    if (this.userExist){
      this.loaduser();
    }
    else
    this.router.navigate(['/login']);

  }

  openModal(template: TemplateRef<any>, type) {
    delete this.type;
    delete this.message;
    this.type = type;

    this.submit = false;

    this.modalRef = this.modalService.show(template,{
      backdrop: 'static',
    });
 }

  sendmsg(){
    this.submit = true;

    if (!this.email || !this.message)
    return;

    this.dataService.saveSubItem(this.tablemsg, this.currentUser.uid, "messages", {id: UUID.UUID(), email: this.email, content: this.message, type: this.type} ).then((data) => {
      delete this.email;
      delete this.message;
      
      this.modalRef.hide()
    })
  }

}
